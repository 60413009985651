import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { AwesomeButton } from "react-awesome-button";
import classes from "./Team.module.scss";

import ImageTeamAbe from '../assets/images/team-abe.jpg';
import ImageTeamJaime from '../assets/images/team-jaime.jpg';
import ImageTeamCaroline from '../assets/images/team-caroline.jpg';
import ImageTeamContributors from '../assets/images/salsas.gif';

const Rarity = () => {
    return (
        <div id="team" className={`section ${classes.team}`}>
            <Container>
                <Row>
                    <Col>
                        <h1 className="display-1 mb-3 text-center">Team</h1>
                        <Container className={classes.teamContainer}>
                            <Row>
                                <Col lg={6}>
                                    <Card className={`ps-2 pe-2 ${classes.teamCard}`}>
                                        <Card.Body>
                                            <Card.Img variant="top" className={classes.teamCardImage} src={ImageTeamAbe} />
                                            <Card.Title className={classes.teamCardTitle}>Abraham Yepremian</Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">Software Engineer</Card.Subtitle>
                                            <Card.Text>
                                                Abraham is a full stack engineer with blockchain development experience. He left his dream job as a Software Engineer at The Walt Disney Company to work on Salsa full time. He's most excited to develop utility with NFTs.
                                            </Card.Text>
                                            <Card.Link target="_blank" href="https://www.linkedin.com/in/ayepremian/">LinkedIn</Card.Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className={`ps-2 pe-2 ${classes.teamCard}`}>
                                        <Card.Body>
                                            <Card.Img variant="top" className={classes.teamCardImage} src={ImageTeamJaime} />
                                            <Card.Title className={classes.teamCardTitle}>Jaime Olmos</Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">Artist</Card.Subtitle>
                                            <Card.Text>
                                                Jaime is a kid at heart who enjoys developing original and lighthearted  characters and stories. He dreams of reaching people's hearts in the same way that the creative stories he's admired since childhood reach his own.
                                            </Card.Text>
                                            <Card.Link target="_blank" href="https://www.linkedin.com/in/jaime-olmos-a7586321a/">LinkedIn</Card.Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card className={`ps-2 pe-2 ${classes.teamCard}`}>
                                        <Card.Body>
                                            <Card.Img variant="top" className={classes.teamCardImage} src={ImageTeamCaroline} />
                                            <Card.Title className={classes.teamCardTitle}>Caroline Hartley</Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">Marketer</Card.Subtitle>
                                            <Card.Text>
                                                Crypto fan since 2013. NFT fan since 2021. Caroline is a digital marketer with a passion for startups, defi, and gaming. She's very excited for the emergence of the metaverse.
                                            </Card.Text>
                                            <Card.Link target="_blank" href="https://www.linkedin.com/in/carolinehartley1998/">LinkedIn</Card.Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className={`ps-2 pe-2 ${classes.teamCard}`}>
                                        <Card.Body>
                                            <Card.Img variant="top" className={classes.teamCardImage} src={ImageTeamContributors} />
                                            <Card.Title className={classes.teamCardTitle}>And many more contributors</Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">Experts in their respective fields</Card.Subtitle>
                                            <Card.Text>
                                                Outside of our core team, we are collaborating with different experts in their respective fields to ensure a project of the highest quality possible.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="mb-5">
                                <Col className="text-center">
                                    <AwesomeButton 
                                        className={classes.button} 
                                        type="primary"
                                        href="https://medium.com/@SalsaNFT/transparent-founders-meet-the-team-behind-salsa-nft-a4da1c54bd56"
                                        target="_blank"
                                    >
                                        Read More
                                    </AwesomeButton>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Rarity
