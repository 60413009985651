import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaTwitter, FaMediumM, FaDiscord, FaHeart } from 'react-icons/fa';
import classes from "./Footer.module.scss";

const Footer = () => {
    return (
        <div id="footer" className={`section ${classes.footer}`}>
            <Container>
                <Row className="align-items-end">
                    <Col md={4} className="text-md-start text-center">
                        <a href="/terms">Terms & Conditions</a>
                        <a href="mailto:contact@joinsalsa.com">contact@joinsalsa.com</a>
                    </Col>
                    <Col md={4} className="mt-4 mt-md-0 text-center">
                        <a href="https://twitter.com/SalsaNFT" target="_blank" rel="noreferrer"><FaTwitter className={classes.socialIcon} /></a>
                        <a href="https://medium.com/@SalsaNFT" target="_blank" rel="noreferrer"><FaMediumM className={classes.socialIcon} /></a>
                        <a href="https://discord.gg/salsa" target="_blank" rel="noreferrer"><FaDiscord className={classes.socialIcon} /></a>
                    </Col>
                    <Col md={4} className="mt-5 mt-md-0 text-md-end text-center">
                        All Rights Reserved © 2021<br/>Made with <FaHeart className={classes.heart}/> by BlockMotive
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer
