import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import classes from "./Rarity.module.scss";
import ImageRarity from '../assets/images/interstellar.jpg';

const Rarity = () => {
    return (
        <div id="rarity" className={`section ${classes.rarity}`}>
            <Container>
                <Row>
                    <Col>
                        <Container className={classes.rarityContainer}>
                            <Row className="d-flex justify-content-center">
                                <h1 className="display-1">Salsa NFT Rarity</h1>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Col sm={{span:10}} md={6} lg={{span:5}} xl={{span:4}}>
                                    <h3 className="mt-3">Variations</h3>
                                    <p className="m-3">Each Salsa is <strong>unique</strong> and combines features from a pool of <strong>600+ traits</strong> with <strong>different rarities</strong></p>
                                    <ListGroup className={classes.listGroup} variant="flush" as="ol">
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">17</span>
                                            <span>backgrounds</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">138</span>
                                            <span>hair styles</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">29</span>
                                            <span>dresses</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">28</span>
                                            <span>items</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">18</span>
                                            <span>hands</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">10</span>
                                            <span>ears</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">3</span>
                                            <span>skin tones</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">62</span>
                                            <span>eyes</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">86</span>
                                            <span>mouths</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">28</span>
                                            <span>eyelids</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">23</span>
                                            <span>headbands</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">138</span>
                                            <span>bangs</span>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    <p className="m-3">Some Salsas also come with <strong>earrings, stickers</strong> and / or <strong>eyewear</strong></p>
                                    <ListGroup className={classes.optionalListGroup} variant="flush" as="ol">
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">8</span>
                                            <span>earrings</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">8</span>
                                            <span>stickers</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">8</span>
                                            <span>eyewear</span>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col sm={{span:10}} md={6} lg={{span:5, offset:1}} xl={{span:4, offset:1}}>
                                    <h3 className="mt-3">Rarity</h3>
                                    <p className="m-3">Some trait variants are rarer than others. In general a variant can have one of the following rarities:</p>
                                    <ListGroup className={classes.listGroup} variant="flush" as="ol">
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">Common</span>
                                            <span>5% and above</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">Uncommon</span>
                                            <span>1.5% - 5%</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">Rare</span>
                                            <span>0.6% - 1.5%</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">Legendary</span>
                                            <span>0.2% - 0.6%</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-start" as="li">
                                            <span className="fw-bold">Cosmic</span>
                                            <span>0.01% - 0.2%</span>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    <h3 className="mt-3">Interstellar Salsas</h3>
                                    <p className="m-3">We have also hidden some 1 of 1 hand-crafted Salsas that completely deviate from the trait pool.</p>
                                    <img src={ImageRarity} className={classes.rarityImage} alt="Interstellar Salsas"/>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Rarity
