import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from "./Faq.module.scss";

const Faq = () => {
    return (
        <div id="faq" className={`section ${classes.faq}`}>
            <Container>
                <Row>
                    <Col>
                        <h1 className="display-1 mb-3 text-center">FAQ</h1>
                        <Container className={classes.faqContainer}>
                            <h2>What are Salsas?</h2>
                            <p>
                                Salsas are a collection of 10,000 randomly generated NFTs.
                            </p>
                        </Container>
                        <Container className={classes.faqContainer}>
                            <h2>When will minting begin and how much will it cost?</h2>
                            <p>
                                Salsa is currently on hold and looking to come back strong in 2022. Both the mint date and price are to be determined.
                            </p>
                        </Container>
                        <Container className={classes.faqContainer}>
                            <h2>Will there be a pre-sale?</h2>
                            <p>
                                No, but we will make sure active community members are rewarded. More information will be released closer to launch.
                            </p>
                        </Container>
                        <Container className={classes.faqContainer}>
                            <h2>Is there a limit to how many Salsas I can mint?</h2>
                            <p>
                                You can only buy 1 Salsa per transaction. However, there is no limit to the number of transactions you can make.
                            </p>
                        </Container>
                        <Container className={classes.faqContainer}>
                            <h2>How do we mint a Salsa?</h2>
                            <p>
                                When minting is opened, you will mint your Salsas directly on our website by connecting your blockchain wallet and clicking the mint button.
                            </p>
                        </Container>
                        <Container className={classes.faqContainer}>
                            <h2>Will there be a secondary market?</h2>
                            <p>
                                Yes, we are currently working to list Salsa on all relevant NFT marketplaces.
                            </p>
                        </Container>
                        <Container className={classes.faqContainer}>
                            <h2>Do you have a rarity system?</h2>
                            <p>
                                Yes! We have 600 traits, so each Salsa will be unique. Some Salsas will be exceptionally rare such as our 1:1s and scarce traits.
                            </p>
                        </Container>
                        <Container className={classes.faqContainer}>
                            <h2>Will there be a community fund?</h2>
                            <p>
                            Yes! We will be placing 10% of our NFT sales and secondary market royalties towards community initiatives. This fund will ensure we are able to grow the Salsa ecosystem and community.
                            </p>
                        </Container>
                        <Container className={classes.faqContainer}>
                            <h2>Are there secondary sale royalties?</h2>
                            <p>
                                Yes, royalties are set at 5%.
                            </p>
                        </Container>
                        <Container className={classes.faqContainer}>
                            <h2>Will children purchase the NFT themselves?</h2>
                            <p>
                                No, we fully expect that anyone who purchases a NFT from our website to be 18 or older, or of the legal age in their jurisdiction.
                            </p>
                        </Container>
                    </Col>
                </Row>
            </Container>                       
        </div>
    )
}

export default Faq
