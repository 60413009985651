import LazyHero from 'react-lazy-hero';
import ImageHero from '../assets/images/hero-no-salsa.jpg';
import ImageFakeSalsa from '../assets/images/salsa-flying.png';
import { BsChevronDoubleDown } from 'react-icons/bs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import classes from "./Hero.module.scss";

const Hero = () => {
    return (
        <div>
            <LazyHero minHeight='100vh' opacity={0} parallaxOffset={100} transitionDuration={0} imageSrc={ImageHero} >
                <a href="#about">
                    <BsChevronDoubleDown className={classes.animatedArrow} aria-hidden="true" />
                </a>
                <Row className={classes.salsaFeature}>
                    <Col>
                        <div className={classes.speechBubble}>
                            <h1 className={classes.message}>Empower blockchain education for children!</h1>
                            <AwesomeButton 
                                className={classes.button} 
                                type="secondary"
                                href="https://twitter.com/SalsaNFT"
                                target="_blank"
                            >
                                Follow Twitter
                            </AwesomeButton>
                            <AwesomeButton
                                className={classes.button}
                                type="primary"
                                href="https://discord.gg/salsa"
                                target="_blank"
                            >
                                Join Discord
                            </AwesomeButton>
                        </div>
                    </Col>
                    <Col className={classes.salsaImageContainer}>
                        <img className={classes.salsaImage} src={ImageFakeSalsa} alt="Salsa Flying" />
                    </Col>
                </Row>
            </LazyHero>
        </div>
    )
}

export default Hero
