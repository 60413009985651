import {Container, Navbar as BNavbar, Nav} from 'react-bootstrap';
import ImageLogo from '../assets/images/logo.png';
import { FaTwitter, FaDiscord } from 'react-icons/fa';
import classes from "./Navbar.module.scss";

const Navbar = () => {
    return (
        <BNavbar className={classes.navbar} fixed="top" expand="lg" collapseOnSelect>
            <Container>
                <BNavbar.Brand href="#home"><img src={ImageLogo} className={classes.brand} alt="Logo" /></BNavbar.Brand>
                <BNavbar.Toggle aria-controls="basic-navbar-nav" className={classes.toggler} onClick="" />
                <BNavbar.Collapse id="basic-navbar-nav" className={classes.linksContainer}>
                    <Nav className={`mx-auto ${classes.links}`}>
                        <Nav.Link className={classes.linkAbout} href="#about">About</Nav.Link>
                        <Nav.Link className={classes.linkStory} href="#story">Story</Nav.Link>
                        <Nav.Link className={classes.linkRarity} href="#rarity">Rarity</Nav.Link>
                        <Nav.Link className={classes.linkUtility} href="#utility">Utility</Nav.Link>
                        <Nav.Link className={classes.linkRoadmap} href="#roadmap">Roadmap</Nav.Link>
                        <Nav.Link className={classes.linkTeam} href="#team">Team</Nav.Link>
                        <Nav.Link className={classes.linkFaq} href="#faq">FAQ</Nav.Link>
                    </Nav>
                    <Nav className={classes.socials}>
                        <Nav.Link className={classes.social} href="https://twitter.com/SalsaNFT"><FaTwitter /></Nav.Link>
                        <Nav.Link className={classes.social} href="https://discord.gg/salsa"><FaDiscord /></Nav.Link>
                    </Nav>
                </BNavbar.Collapse>
            </Container>
        </BNavbar>
    )
}

export default Navbar;
