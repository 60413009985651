import Navbar from "../components/Navbar";
import Hero from '../components/Hero';
import About from '../components/About';
import Story from '../components/Story';
import Rarity from '../components/Rarity';
import Utility from '../components/Utility';
import Roadmap from '../components/Roadmap';
import Team from '../components/Team';
import Faq from '../components/Faq';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Story />
      <Rarity />
      <Utility />
      <Roadmap />
      <Team />
      <Faq />
      <Footer />
    </div>
  );
}

export default Home;
