import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MouseParticles from "react-mouse-particles";
import Home from "./pages/Home";
import Terms from "./pages/Terms";

function App() {
  return (
      <Router>
        <MouseParticles
          g={2.3}
          num={1}
          radius={8}
          life={0.8}
          v={1.2}
          color="random"
          alpha={0.16}
          level={6}
        />
        <Switch>
            <Route path="/" exact>
                <Home />
            </Route>
            <Route path="/terms">
                <Terms />
            </Route>
        </Switch>
      </Router>
  );
}

export default App;
