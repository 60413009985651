import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AwesomeButton } from "react-awesome-button";
import classes from "./Utility.module.scss";
import ImageMobileApp1 from '../assets/images/app1.jpg';
import ImageMobileApp2 from '../assets/images/app2.jpg';
import ImageSalsaCommunity from '../assets/images/salsa-community.png';
import ImageMystery from '../assets/images/mystery.jpg';
import ImageSalsaDiscord from '../assets/images/salsa-discord.jpg';
import ImageSalsaStory from '../assets/images/salsa-book-girl.jpg';
import ImageSalsaGraduation from '../assets/images/salsa-graduation.jpg';
import ImageSalsaPhone from '../assets/images/salsa-phone.jpg';
import ImageSalsaYou from '../assets/images/salsa-we-want-you.jpg';
import PdfStory from '../assets/docs/story.pdf';
import "react-awesome-button/dist/styles.css";


const Utility = () => {
    return (
        <div id="utility" className={`section ${classes.utility}`}>
            <h1 className="display-1">Utility</h1>
            <p className="mb-3">Perks for NFT holders and the general Salsa community</p>

            <Container>
                <Row className="d-flex justify-content-center">
                    <Col lg={6} className="mb-4">
                        <Container className={classes.utilityContainer}>
                            <h2>Child Friendly & Educational</h2>
                            <p>We are building a child friendly crypto education app where users can</p>
                            <ul className={ `text-start ${classes.mobileAppBullets}` }>
                                <li>Learn from educational content</li>
                                <li>Earn experience points by completing lessons</li>
                                <li>Share content with the Salsa community</li>
                                <li>Customize their Salsa characters</li>
                            </ul>
                            <div className={classes.mobileAppImageContainer}>
                                <img src={ImageMobileApp2} className={classes.secondMobileAppImage} alt="Second Salsa App Preview"/>
                                <img src={ImageMobileApp1} className={classes.firstMobileAppImage} alt="First Salsa App Preview"/>
                            </div>
                            <p>The progressive web application works in browsers and on mobile devices.</p>
                        </Container>
                    </Col>
                    <Col lg={6} className="mb-4">
                        <Container className={classes.utilityContainer}>
                            <h2>Community Driven</h2>
                            <p>We are a community driven project that aims to deliver for and grow our community.</p>
                            <p>As part of this effort, <span className="fw-bold">10% of our NFT sales revenue and secondary market royalties</span> will be moved to a community fund where the <span className="fw-bold">community decides</span> how we can best use it!</p>
                            <p>We are also looking into having in person events to celebrate Salsa!</p>
                            <img src={ImageSalsaCommunity} className={classes.salsaCommunityImage} alt="Salsa Community"/>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Container fluid className={ `mt-5 mb-5 ${classes.utilityMerchContainer}` }>
                <div className={classes.utilityMerchCta}>
                    <h2>Merchandise</h2>
                    <p>We plan to develop custom merchandise with your Salsas. Until then, enjoy shopping our pre-launch merch!</p>
                    <AwesomeButton 
                        className={classes.button} 
                        type="primary"
                        href="https://shop.joinsalsa.com/"
                        target="_blank"
                    >
                        Shop Now
                    </AwesomeButton>
                </div>
            </Container>
            
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col lg={8}>
                        <Container className={classes.utilityContainer}>
                            <h2 className="mb-3">... and so much more</h2>

                            <Row className="mt-5 align-items-center">
                                <Col lg={{ span: 5, offset: 1 }}>
                                    <h3>Expect Companion Airdrops</h3>
                                    <p>All holders will receive a companion to their Salsa NFT. Our next character is already being worked on!</p>
                                </Col>
                                <Col>
                                    <img src={ImageMystery} className={classes.utilityImageBorder} alt="Mystery"/>
                                </Col>
                            </Row>
                        
                            <Row className="mt-5 align-items-center">
                                <Col lg={{ span: 5, order: 2 }}>
                                    <h3>Get Exclusive Discord Access</h3>
                                    <p>Ownership of a Salsa grants you access to Salsa only holder sections of our Discord server where we will have special giveaways and opportunities.</p>
                                    <AwesomeButton 
                                        className={ `${classes.button} d-none d-lg-inline-block` } 
                                        type="primary"
                                        href="https://discord.gg/salsa"
                                        target="_blank"
                                    >
                                        Join Discord
                                    </AwesomeButton>
                                </Col>
                                <Col lg={{ span: 6 }}>
                                    <img src={ImageSalsaDiscord} className={classes.utilityImage} alt="Exclusive Discord Access"/>
                                </Col>
                            </Row>
                            <Row className="mt-3 d-sm-block d-md-block d-lg-none">
                                <Col>
                                    <AwesomeButton 
                                        className={classes.button} 
                                        type="primary"
                                        href="https://discord.gg/salsa"
                                        target="_blank"
                                    >
                                        Join Discord
                                    </AwesomeButton>
                                </Col>
                            </Row>

                            <Row className="mt-5 align-items-center">
                                <Col lg={{ span: 5, offset: 1 }}>
                                    <h3>Enjoy A Never Ending Story</h3>
                                    <p>We will continue to develop the Salsa storyline and expand into children's books, digital media, and more.</p>
                                    <AwesomeButton 
                                        className={ `${classes.button} d-none d-lg-inline-block` } 
                                        type="primary"
                                        href={PdfStory}
                                        target="_blank"
                                    >
                                        Read Story
                                    </AwesomeButton>
                                </Col>
                                <Col>
                                    <img src={ImageSalsaStory} className={classes.utilityImageBorder} alt="Salsa Story"/>
                                </Col>
                            </Row>
                            <Row className="mt-3 d-sm-block d-md-block d-lg-none">
                                <Col>
                                <AwesomeButton 
                                    className={classes.button} 
                                    type="primary"
                                    href={PdfStory}
                                    target="_blank"
                                >
                                    Read Story
                                </AwesomeButton>
                                </Col>
                            </Row>

                            <Row className="mt-5 align-items-center">
                                <Col lg={{ span: 5, order: 2 }}>
                                    <h3>Win Salsa Scholarships</h3>
                                    <p>After our sales are complete and our application is built, we would like to open a Salsa scholarship application for holders.</p>
                                </Col>
                                <Col lg={{ span: 6 }}>
                                    <img src={ImageSalsaGraduation} className={classes.utilityImage} alt="Salsa Scholarships"/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <p>Have a kid or niece or nephew that can benefit from a college scholarship? Redeem your in-app experience points (XP) to submit an application.</p>
                                    <p>If not selected, your XP will be returned. If selected, scholarship funds will be put into a smart contract that can be withdrawn when the child turns 18.</p>
                                </Col>
                            </Row>

                            <Row className="mt-5 align-items-center">
                                <Col lg={{ span: 5, offset: 1 }}>
                                    <h3>Help Build A Tech Community</h3>
                                    <p>Salsa was incepted more than a year ago and blockchain technology is the perfect environment to see her finally come to life.</p>
                                </Col>
                                <Col>
                                    <img src={ImageSalsaPhone} className={classes.utilityImage} alt="We Want You"/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <p>After our sales are complete, we will organize Salsa Hackathons with prizes for varying categories such as memes, artwork, web development, and game development.</p>
                                    <p>We want to foster our tech community, give access to our APIs, and see what the community can build with our Salsas!</p>
                                </Col>
                            </Row>

                            <div className="mt-5">
                                <h3>And many other community ideas</h3>
                                <p>We are all in it for the long-term. The project will be the very first NFT many children will come in contact with. It will be their companion for many years to come.</p>
                                <p>With the help of our community, the core team will make sure to continuously grow and foster the Salsa ecosystem.</p>
                                <div className="text-center">
                                    <img src={ImageSalsaYou} className={classes.weWantYou} alt="Salsa Wants You"/>
                                </div> 
                            </div>

                        </Container>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Utility
