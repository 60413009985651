import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AwesomeButton } from "react-awesome-button";
import classes from "./Story.module.scss";
import ImageStory from '../assets/images/banner.jpg';
import PdfStory from '../assets/docs/story.pdf';
import "react-awesome-button/dist/styles.css";


const Story = () => {
    return (
        <div id="story" className={`section ${classes.story}`}>
            <Container>
                <Row>
                    <Col>
                        <Container className={classes.container}>
                            <Row className="d-flex justify-content-center">
                                <Col className={classes.storyContainer} md={10} lg={8}>
                                    <h1 className="display-1">Salsa's Origin Story</h1>
                                    <p className={classes.storyText}>
                                        The Salsas are a group of playful little humanoid children.
                                        They love to run around and play hide-and-seek. Don’t let their big eyes and messy hair fool you, though!
                                        The Salsas are quite smart and resourceful. They come from the Enchilox Galaxy where their mother,
                                        Totilla the Kind Cosmic Watcher of the Universe, created them. She is a wise goddess with many powerful abilities;
                                        however, she is not allowed to directly help the creatures she encounters around the universe.
                                        This restriction plus her wish to have a family, led her to create the thousands of Salsas.
                                        They all live peacefully in their home, The Molcascade.
                                    </p>
                                    <p className={classes.storyText}>
                                        As the Salsas grow a bit older, Totilla asks them if they wish to travel to Planet Earth
                                        in order to protect its people. After all, she was never told her children couldn’t take action.
                                        Of course, all the Salsas want to help. Using toy-capsules as their aircrafts, they all follow their mother
                                        to the faraway planet. None of the Salsas can contain their eagerness. They may be a bit high-spirited and
                                        mischievous at times, but their most defining trait is their pure heart. They are more than ready to bring
                                        joy to whoever they meet.
                                    </p>
                                    <AwesomeButton 
                                        className={classes.button} 
                                        type="primary" 
                                        href={PdfStory}
                                        target="_blank"
                                    >
                                        Read Full Story
                                    </AwesomeButton>
                                    <img src={ImageStory} className={classes.storyImage} alt="Salsa Buddies" />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Story
