import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tilt from 'react-parallax-tilt';
import { AwesomeButton } from "react-awesome-button";
import { FaTwitter, FaDiscord } from 'react-icons/fa';
import ImageGif from '../assets/images/salsas.gif';
import ImageCollage from '../assets/images/collage.jpg';
import Marquee from "react-fast-marquee";
import classes from "./About.module.scss";
import "react-awesome-button/dist/styles.css";


const About = () => {
    return (
        <div id="about" className={`section ${classes.about}`}>
            <Container className={classes.container}>
                <Row>
                    <Col className={classes.gifCol} xs={12} lg={5}>
                        <Tilt scale={1.15} transitionSpeed={2500}>
                            <img width="100%" src={ImageGif} alt="Salsa Examples" />
                        </Tilt>
                    </Col>
                    <Col className={classes.aboutText} xs={12} lg={7}>
                        <h2>Get Your Salsa!</h2>
                        <p>Salsas are a collection of programmatically generated child friendly NFTs.
                            <b> Salsa's mission is to empower blockchain education for children</b> and to provide a character
                            based companion. Our team will build a <b>mobile friendly web app</b> that kids can use to learn various
                            topics and interact within the community. Moreover, we are working full time to build out the
                            Salsa brand with <b>future airdrops</b> and <b>custom merchandise</b>.
                        </p>
                        <p>
                            There are <b>10,000</b> randomly assembled Salsas from over <b>600 attributes</b> and <b>15 traits</b>!
                            Salsa is currently on hold, but we are planning to come back strong in 2022.
                        </p>
                        <p>
                            Join our Discord server for updates!
                        </p>
                        <AwesomeButton href="https://discord.gg/salsa" target="_blank" rel="noreferrer" className={classes.button} type="primary">Join Discord</AwesomeButton>
                    </Col>
                </Row>
            </Container>
            <Marquee gradientWidth={100} className={classes.marquee}>
                <img src={ImageCollage} alt = "Salsa Gallery"/>
                <img src={ImageCollage} alt = "Salsa Gallery"/>
            </Marquee>
            <div className={classes.cta}>
                <a href="https://twitter.com/SalsaNFT" target="_blank" rel="noreferrer"><FaTwitter className={classes.socialIcon} /></a>
                <a href="https://discord.gg/salsa" target="_blank" rel="noreferrer"><FaDiscord className={classes.socialIcon} /></a>
            </div>
        </div>
    )
}

export default About
