import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ImCheckmark, ImCheckmark2 } from 'react-icons/im';
import PdfStory from '../assets/docs/story.pdf';
import classes from "./Roadmap.module.scss";

const Roadmap = () => {
    return (
        <div id="roadmap" className={`section ${classes.roadmap}`}>
            <Container className={classes.rarityContainer}>
                <Row className="d-flex justify-content-center">
                    <h1 className="display-1">Salsa Roadmap</h1>
                </Row>

                <Row>
                    <div className={classes.timeline}>
                        <div className={classes.timelineRow}>
                            <div className={classes.timelineTime}>
                                Phase 0<small>Pre-launch</small>
                            </div>
                            <div className={classes.timelineContentDone}>
                                <h4>Conception</h4>
                                <ul className={classes.roadmapList}>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} />First Salsa sketched + <a className={classes.roadmapLink} href={PdfStory} target="_blank" rel="noreferrer">written story</a> (March 2020)</li>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} />Turn Salsa into NFTs (July 2021)</li>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} />Leave everything behind to work on Salsa full time!</li>
                                </ul>
                            </div>
                        </div>

                        <div className={classes.timelineRow}>
                            <div className={classes.timelineTime}>
                                Phase 1<small>Pre-launch</small>
                            </div>
                            <div className={classes.timelineContent}>
                                <h4>Pre-Launch</h4>
                                <ul className={classes.roadmapList}>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} /><a className={classes.roadmapLink} href="https://twitter.com/SalsaNFT" target="_blank" rel="noreferrer">Twitter</a> Campaign</li>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} /><a className={classes.roadmapLink} href="https://www.instagram.com/salsa.nft/" target="_blank" rel="noreferrer">Instagram</a> Campaign</li>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} /><a className={classes.roadmapLink} href="https://www.linkedin.com/posts/ayepremian_blockchain-education-future-activity-6857938113969422336-6Hs2/" target="_blank" rel="noreferrer">LinkedIn</a> Campaign</li>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} /><a className={classes.roadmapLink} href="https://medium.com/@SalsaNFT" target="_blank" rel="noreferrer">Medium</a> Articles</li>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} />Website</li>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} />Design <a className={classes.roadmapLink} href="#utility">mobile web app</a> dedicated to educate children about blockchain/crypto</li>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} />Pre-launch <a className={classes.roadmapLink} href="https://shop.joinsalsa.com/" target="_blank" rel="noreferrer">merchandise store</a></li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} /><a className={classes.roadmapLink} href="https://discord.gg/salsa" target="_blank" rel="noreferrer">Discord</a> community growth (join us!)</li>
                                    <li><ImCheckmark className={classes.checkmarkCompleted} />Share on listing platforms</li>
                                </ul>
                            </div>
                        </div>

                        <div className={classes.timelineRow}>
                            <div className={classes.timelineTime}>
                                Phase 2<small>2022</small>
                            </div>
                            <div className={classes.timelineContent}>
                                <h4>Launch</h4>
                                <ul className={classes.roadmapList}>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Launch our project with 10,000 Salsas</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Giveaways + fun surprises</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Secondary marketplace listings</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Continue working on Salsa's companions</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Donation to <a className={classes.roadmapLink} href="https://preventchildabuse.org/" target="_blank" rel="noreferrer">Prevent Child Abuse</a></li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Integration to Rarity Tool</li>
                                </ul>
                            </div>
                        </div>

                        <div className={classes.timelineRow}>
                            <div className={classes.timelineTime}>
                                Phase 3<small>2022</small>
                            </div>
                            <div className={classes.timelineContent}>
                                <h4>Continue Development</h4>
                                <ul className={classes.roadmapList}>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Upgrade merch store to integrate with your Salsas</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Add a child development specialist to the team</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Begin application development</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Continue working on Salsa's companions</li>
                                </ul>
                            </div>
                        </div>

                        <div className={classes.timelineRow}>
                            <div className={classes.timelineTime}>
                                Phase 4<small>2022</small>
                            </div>
                            <div className={classes.timelineContent}>
                                <h4>Beta & Airdrop</h4>
                                <ul className={classes.roadmapList}>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Open application beta, share API specs, and iteratively improve from feedback</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Airdrop our companions to Salsa</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Release coloring book</li>
                                </ul>
                            </div>
                        </div>

                        <div className={classes.timelineRow}>
                            <div className={classes.timelineTime}>
                                Phase 5<small>2022</small>
                            </div>
                            <div className={classes.timelineContent}>
                                <h4>Future</h4>
                                <ul className={classes.roadmapList}>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />App improvements</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Release children's book</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Launch Salsa hackathon with prizes</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Salsa Scholarships</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Continued marketing campaigns + brand growth</li>
                                    <li><ImCheckmark2 className={classes.checkmarkTodo} />Explore growth opportunities and decide as a community what to build next</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </Row>
            </Container>

        </div>
        
    )
}

export default Roadmap
